import React from "react";
import styles from "./GlobalLoader.module.scss";

function GlobalLoader() {
  return (
    <div className={styles.loaderMainDiv}>
      <div className={styles.loader}>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
        <div className={styles.circle}></div>
      </div>
    </div>
  );
}

export default GlobalLoader;
