import React, { useState } from "react";
import { PiChatTeardropDotsBold } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import contactCss from "./Contact.module.scss";
import {
  contactTitle,
  contactDescription,
  contactFullNameLabel,
  contactFullNamePlaceholder,
  contactEmailLabel,
  contactEmailPlaceholder,
  contactMessageLabel,
  contactMessagePlaceholder,
  contactSendBtn,
  contactMessageSending,
  contactMessageSent,
  contactMessageFailed,
} from "../../utility/en";
import {
  validateFullName,
  validateEmail,
  validateMessage,
} from "../../utility/Functions";

function Contact() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [result, setResult] = useState("");
  const [errors, setErrors] = useState({});

  const toggleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  const validateFormField = (name, value) => {
    let error = "";
    if (name === "fullName") {
      error = validateFullName(value);
    } else if (name === "email") {
      error = validateEmail(value);
    } else if (name === "message") {
      error = validateMessage(value);
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    validateFormField(name, value);
  };

  const validateForm = (formData) => {
    const newErrors = {};
    const fullNameError = validateFullName(formData?.fullName);
    const emailError = validateEmail(formData?.email);
    const messageError = validateMessage(formData?.message);

    if (fullNameError) newErrors.fullName = fullNameError;
    if (emailError) newErrors.email = emailError;
    if (messageError) newErrors.message = messageError;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.target).entries());

    if (!validateForm(formData)) return;
    setResult(contactMessageSending);
    const formBody = new FormData(event.target);
    formBody.append("access_key", process.env.REACT_APP_FORM_API_KEY);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formBody,
    });

    const data = await response.json();
    if (data.success) {
      setResult(contactMessageSent);
      event.target.reset();
      setTimeout(() => setModalOpen(false), 3000);
    } else {
      setResult(contactMessageFailed);
    }
  };

  return (
    <div>
      <button
        className={contactCss.contactButton}
        aria-label="contactBtn"
        onClick={toggleModal}
      >
        {isModalOpen ? (
          <IoClose size={30} />
        ) : (
          <PiChatTeardropDotsBold size={30} />
        )}
      </button>

      {isModalOpen && (
        <div className={contactCss.modalOverlay}>
          <div className={contactCss.modalContent}>
            <div className={contactCss.modalTitleDiv}>
              <h5 className={contactCss.modalTitle}>{contactTitle}</h5>
              <p className={contactCss.modalDescription}>
                {contactDescription}
              </p>
            </div>
            <form onSubmit={onSubmit} className={contactCss.form}>
              <div className={contactCss.formGroup}>
                <label htmlFor="fullName" className={contactCss.label}>
                  {contactFullNameLabel}
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  autoComplete="on"
                  placeholder={contactFullNamePlaceholder}
                  className={`${
                    errors.fullName ? contactCss.errorInput : contactCss.input
                  }`}
                  onChange={handleInputChange}
                />
                {errors.fullName && (
                  <span className={contactCss.errorText}>
                    {errors.fullName}
                  </span>
                )}
              </div>
              <div className={contactCss.formGroup}>
                <label htmlFor="email" className={contactCss.label}>
                  {contactEmailLabel}
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="on"
                  placeholder={contactEmailPlaceholder}
                  className={`${
                    errors.email ? contactCss.errorInput : contactCss.input
                  }`}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <span className={contactCss.errorText}>{errors.email}</span>
                )}
              </div>
              <div className={contactCss.formGroup}>
                <label htmlFor="message" className={contactCss.label}>
                  {contactMessageLabel}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  maxLength={251}
                  autoComplete="on"
                  placeholder={contactMessagePlaceholder}
                  className={`${
                    errors.message ? contactCss.errorInput : contactCss.input
                  }`}
                  onChange={handleInputChange}
                />
                {errors.message && (
                  <span className={contactCss.errorText}>{errors.message}</span>
                )}
              </div>
              <button type="submit" className={contactCss.submitButton}>
                {contactSendBtn}
              </button>
              {result && <p className={contactCss.result}>{result}</p>}
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
