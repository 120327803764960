import React from "react";
import LayoutCss from "./Layout.module.scss";
import Header from "../components/Header/Header";
import Footer from "../components/FooterSection/Footer";
import Contact from "../components/Contact/Contact";

function Layout({ children }) {
  return (
    <div className={LayoutCss.layout}>
      <Header />
      <main className={LayoutCss.mainContent}>{children}</main>
      <Contact />
      <Footer />
    </div>
  );
}

export default Layout;
