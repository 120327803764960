import React from "react";
import footerCss from "./Footer.module.scss";
import {
  footerLogoText,
  footerAllRightsReserved,
  footerMadeWith,
} from "../../utility/en";

function Footer() {
  return (
    <div className={footerCss.footerMainDiv}>
      <div className={footerCss.footer}>
        <div className={footerCss.footerText}>
          <p className={footerCss.logoText}>{footerLogoText}</p>
          <p className={footerCss.allRights}>{footerAllRightsReserved}</p>
          <p className={footerCss.madeWith}>{footerMadeWith}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
