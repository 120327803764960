import React, { useEffect, useState } from "react";
import ShootingStarCss from "./ShootingStars.module.scss";

const ShootingStars = () => {
  const [stars, setStars] = useState([]);

  useEffect(() => {
    const createStars = () => {
      // Generate a random number of stars (1 or 2)
      const starCount = Math?.floor(Math?.random() * 2) + 1;
      const starsArray = [];

      for (let i = 0; i < starCount; i++) {
        starsArray.push({
          id: Date?.now() + i,
          left: Math?.random() * 100,
          top: Math?.random() * 100,
          duration: Math?.random() * 2 + 3,
        });
      }
      setStars(starsArray);
    };

    createStars();
    const interval = setInterval(createStars, 8000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={ShootingStarCss.shootingStars}>
      {stars?.map((star) => (
        <div
          key={star?.id}
          className={ShootingStarCss.shootingStar}
          style={{
            left: `${star?.left}vw`,
            top: `${star?.top}vh`,
            animationDuration: `${star?.duration}s`,
          }}
        />
      ))}
    </div>
  );
};

export default ShootingStars;
