import data from "../Data.json";

export const header = data?.header;
export const logoText = header?.logo;
export const navItemsArray = header?.navItems;

export const hero = data?.hero;
export const heroTitle = hero?.title;
export const heroParagraph = hero?.paragraph;
export const heroParagraph2 = hero?.paragraph2;
export const heroButtons = hero?.buttons;

export const workExp = data?.workExp;
export const workExpTitle = workExp?.title;
export const workExpJobs = workExp?.jobs;

export const skills = data?.skills;
export const skillsTitle = skills?.title;
export const skillsArray = skills?.skillsItems;

export const projects = data?.projects;
export const projectsTitle = projects?.title;
export const projectsItems = projects?.projects;
export const projectsViewSource = projects?.viewSource;
export const projectsWebsite = projects?.website;

export const contribution = data?.contribution;
export const contributionTitle = contribution?.title;
export const contributionItems = contribution?.contributions;

export const about = data?.about;
export const aboutTitle = about?.title;
export const aboutPara1 = about?.paragraph1;
export const aboutPara2 = about?.paragraph2;
export const artTitle = about?.artworkTitle;
export const artUrl = about?.artworkUrl;
export const artBtnText = about?.artworkBtnText;

export const projectsPage = data?.projectsPage;
export const projectsPageTitle = projectsPage?.title;
export const projectsPageDescription = projectsPage?.description;
export const projectsPageMoreBtnText = projectsPage?.moreProjectsBtn;
export const projectsPageNoDescriptionText = projectsPage?.noDescriptionText;

export const contributionsPage = data?.contributionsPage;
export const contributionsPageTitle = contributionsPage?.title;
export const contributionsPageDescription = contributionsPage?.description;

export const NotFoundPage = data?.notFound;
export const notFoundDescription = NotFoundPage?.description;
export const notFoundBtnText = NotFoundPage?.backToHome;

export const contact = data?.contact;
export const contactTitle = contact?.title;
export const contactDescription = contact?.description;
export const contactFullNameLabel = contact?.fullNameLabel;
export const contactFullNamePlaceholder = contact?.fullNamePlaceholder;
export const contactEmailLabel = contact?.emailLabel;
export const contactEmailPlaceholder = contact?.emailPlaceholder;
export const contactMessageLabel = contact?.messageLabel;
export const contactMessagePlaceholder = contact?.messagePlaceholder;
export const contactSendBtn = contact?.sendMessageBtn;
export const contactMessageSending = contact?.messageSending;
export const contactMessageSent = contact?.messageSent;
export const contactMessageFailed = contact?.messageFailed;

export const footer = data?.footer;
export const footerLogoText = footer?.footerLogoText;
export const footerAllRightsReserved = footer?.allRightsReserved;
export const footerMadeWith = footer?.madeWith;
