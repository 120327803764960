export const filterSpecificProjects = (projects, filter) => {
  return projects?.filter((project) => {
    // Check if the project name includes any of the filter strings
    return !filter?.some((filterStr) =>
      project?.name?.toLowerCase()?.includes(filterStr?.toLowerCase())
    );
  });
};

// reverseArrayOrder function
export const reverseArrayOrder = (array) => {
  return array?.slice()?.reverse();
};

// filter contributions from specific owners
export function filterByOwnerAndRemoveDuplicates(contributions, excludeOwners) {
  const filtered = contributions?.filter(
    (item) => !excludeOwners?.includes(item?.owner)
  );
  const uniqueByName = [];
  const seenNames = new Set();

  filtered.forEach((item) => {
    if (!seenNames?.has(item?.name)) {
      seenNames?.add(item?.name);
      uniqueByName?.push(item);
    }
  });

  return uniqueByName;
}

// validate FullName function
export const validateFullName = (fullName) => {
  const nameRegex = /^[a-zA-Z\s]+$/;
  if (!fullName) {
    return "Full name cannot be empty.";
  }
  if (!nameRegex.test(fullName)) {
    return "Only letters and spaces are allowed.";
  }
  return "";
};

// validate Email function
export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return "Email cannot be empty.";
  }
  if (!emailRegex.test(email)) {
    return "Please enter a valid email address.";
  }
  return "";
};

// validate Message function
export const validateMessage = (message) => {
  if (!message) {
    return "Message cannot be empty.";
  }
  if (message.length < 12) {
    return "Must be at least 12 characters.";
  }
  if (message.length > 250) {
    return "Cannot exceed 250 characters.";
  }
  return "";
};
