import { createSlice } from "@reduxjs/toolkit";

const pullrequestSlice = createSlice({
  name: "pullrequests",
  initialState: {
    pullrequests: [], // Stores the pullrequests
  },
  reducers: {
    setPullrequests: (state, action) => {
      state.pullrequests = action.payload; // Sets the pullrequest data
    },
  },
});

export const { setPullrequests } = pullrequestSlice.actions;
export default pullrequestSlice.reducer;
