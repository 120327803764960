import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import ShootingStars from "./components/ShootingStars/ShootingStars";
import GlobalLoader from "./components/Loaders/GlobalLoader";

const Home = React.lazy(() => import("./pages/HomePage/Home"));
const About = React.lazy(() => import("./pages/AboutPage/About"));
const Projects = React.lazy(() => import("./pages/ProjectsPage/ProjectsPage"));
const Contributions = React.lazy(() =>
  import("./pages/ContributionsPage/Contributions")
);
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));

function App() {
  return (
    <div className="App">
      <ShootingStars />
      <Router>
        <Layout>
          <Suspense fallback={<GlobalLoader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contributions" element={<Contributions />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
