import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "projects",
  initialState: {
    projects: [], // Stores the repositories
  },
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload; // Sets the repositories data
    },
  },
});

export const { setProjects } = projectSlice.actions;
export default projectSlice.reducer;
