import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Pivot as Hamburger } from "hamburger-react";
import HeaderCss from "./Header.module.scss";
import { logoText, navItemsArray } from "../../utility/en";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  // Function to check if the link is active
  const isActive = (path) => location?.pathname === path;

  // Handle navigation link click
  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  // Disable scrolling on body when menu is open for mobile devices
  useEffect(() => {
    if (isMenuOpen) {
      document?.body?.classList?.add("noScroll");
    } else {
      document?.body?.classList?.remove("noScroll");
    }

    return () => document?.body?.classList?.remove("noScroll");
  }, [isMenuOpen]);

  return (
    <div className={HeaderCss.headerMainDiv}>
      <div className={HeaderCss.header}>
        <div className={HeaderCss.logo}>
          <Link to="/">{logoText}</Link>
        </div>
        <nav
          className={`${HeaderCss.nav} ${
            isMenuOpen ? HeaderCss.activeMenu : ""
          }`}
        >
          {navItemsArray?.map((item) => (
            <Link
              key={item?.url}
              to={item?.url}
              className={isActive(item?.url) ? HeaderCss.activeLink : ""}
              onClick={handleLinkClick}
            >
              {item?.name}
            </Link>
          ))}
        </nav>
        <div className={HeaderCss.hamburger}>
          <Hamburger
            aria-label="hamburger menu"
            toggled={isMenuOpen}
            toggle={setIsMenuOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
