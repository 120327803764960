import { configureStore } from "@reduxjs/toolkit";
import projectReducer from "./projectSlice";
import pullrequestReducer from "./pullrequestSlice";

const store = configureStore({
  reducer: {
    projects: projectReducer,
    pullrequests: pullrequestReducer,
  },
});

export default store;
